@font-face {
  font-family: 'AtlasGroteskWeb';
  src: url('../fonts/AtlasGrotesk-Regular-Web.woff2') format('woff2'),
    url('../fonts/AtlasGrotesk-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'AtlasGroteskWeb';
  src: url('../fonts/AtlasGrotesk-Medium-Web.woff2') format('woff2'),
    url('../fonts/AtlasGrotesk-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}
